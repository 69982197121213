import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeadingBackground from "../images/background_heading.jpg";

function ContactPage() {

  return (
    <Layout>
      <SEO title="Contactez-nous" />

      <section>
        <div className="relative bg-white">
          <div className="absolute inset-0">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
          </div>
          <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <div className="max-w-lg mx-auto">
                <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                  Contactez-nous
                </h2>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                  Pour toutes vos demandes de renseignements ou de devis, vous
                  pouvez utiliser le formulaire. Nous reviendrons
                  trés vite vers vous. Vous pouvez également nous appeler directement.
                </p>
                <dl className="mt-8 text-base leading-6 text-gray-500">
                  <div>
                    <dt className="sr-only">Adresse</dt>
                    <dd>
                      <p>3 Rue Paul Rocache</p>
                      <p>31100 Toulouse</p>
                    </dd>
                  </div>
                  <div className="mt-6">
                    <dt className="sr-only">Téléphone</dt>
                    <dd className="flex">
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>
                      <span className="ml-3">05 82 95 28 67</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">E-mail</dt>
                    <dd className="flex">
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="ml-3">contact@evonergy.fr</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div className="max-w-lg mx-auto lg:max-w-none">
                <form
                  action="/succes/"
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
                >
                  <div className="hidden">
                    <label>
                      Ne pas remplir <input name="bot-field" />
                    </label>
                  </div>
                  <input type="hidden" name="form-name" value="contact" />
                  <div>
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Nom
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="last_name"
                        name="nom"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Prénom
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="first_name"
                        name="prenom"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      E-mail
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Société
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="company"
                        name="societe"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Téléphone
                      </label>
                    </div>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="phone"
                        name="telephone"
                        className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="how_can_we_help"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Comment pouvons nous vous aider?
                      </label>
                    </div>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <textarea
                        id="how_can_we_help"
                        name="message"
                        rows="4"
                        className="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <span class="w-full inline-flex">
                      <button
                        type="submit"
                        class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium shadow-xl text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                      >
                        Envoyer
                      </button>
                    </span>              
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
